// extracted by mini-css-extract-plugin
export var block = "Footer-module--block--1Asem";
export var container = "Footer-module--container--1mSKX";
export var content = "Footer-module--content--3m5-F";
export var first = "Footer-module--first--2BF7v";
export var second = "Footer-module--second--2qP-x";
export var third = "Footer-module--third--2pH9b";
export var social = "Footer-module--social--3bEPA";
export var left = "Footer-module--left--6F0hQ";
export var right = "Footer-module--right--1ZBGc";
export var fourth = "Footer-module--fourth--3o2lJ";
export var bottom = "Footer-module--bottom--12r5M";
export var logo = "Footer-module--logo--3WIZ1";
export var text = "Footer-module--text--1t30e";