import React from "react";

import Link from "../Link";

import * as styles from "./SocialMedia.module.scss";

const SocialMedia = ({ link, img }) => {
  return (
    <Link {...link} className={styles.block}>
      <img {...img} />
    </Link>
  );
};

export default SocialMedia;
