import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../../components/BigTextBlock";
import SwiperBlock from "../../../components/SwiperBlock";

import * as styles from "./BarAmbience.module.scss";

const ambience = {
  Header: (props) => (
    <>
      <div class="text--header">Events</div>
      <br />
      <div {...props} className={`${styles.title} ${props.className || ""}`}>
        Fancy Events with an Athenian Twist
      </div>
    </>
  ),
  text: "Péros is the quintessential spot for Athenian hobnobbing in the most upscale quarter of the city centre. It is also a top venue for parties and festive urban events. Having hosted over the years some of the most memorable nights of the Athenian high society, Péros in Kolonaki offers a perfect glimpse of the local lifestyle and the proverbial finesse of the area. The refined atmosphere of our newly renovated premises blends old-Athens glamour with modern vibes promising to add sparkle to any event.",
};

const swiperImages = [
  <StaticImage
    src={"../../../assets/images/bar/swiper/peros_01.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  // <StaticImage
  //   src={"../../../assets/images/bar/swiper/peros_02.jpg"}
  //   className={styles.swiperImage}
  //   alt={""}
  //   objectFit={"cover"}
  // />,
  <StaticImage
    src={"../../../assets/images/bar/swiper/peros_03.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  // <StaticImage
  //   src={"../../../assets/images/bar/swiper/peros_04.jpg"}
  //   className={styles.swiperImage}
  //   alt={""}
  //   objectFit={"cover"}
  // />,
  <StaticImage
    src={"../../../assets/images/bar/swiper/peros_05.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
];

const BarAmbience = ({ id }) => (
  <div className={styles.ambience} id={id}>
    {/* LEFT */}
    <BigTextBlock className={styles.left} {...ambience} />

    {/* RIGHT */}
    <div className={styles.right}>
      <div className={`${styles.swiper} bar-swiper`}>
        <SwiperBlock slides={swiperImages} slidesPerView={1} />
      </div>
    </div>
  </div>
);

export default BarAmbience;
