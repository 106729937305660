import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import * as styles from "./Navigation.module.scss";

import closeMenuSVG from "../../assets/images/icons/close.svg";

const list = [
  {
    label: "Home",
    link: "#home",
  },
  {
    label: "The Story",
    link: "#the-story",
  },
  {
    label: "Restaurant",
    link: "#restaurant",
  },
  {
    label: "All-Day Cafe",
    link: "#all-day-cafe",
  },
  {
    label: "Bar & Cigar Lounge",
    link: "#bar-n-cigar-lounge",
  },
  {
    label: "Special Events",
    link: "#special-events",
  },
  {
    label: "Contact & Location",
    link: "#contact-n-location",
  },
];

const background = {
  src: "../../assets/images/navigation/peros_01.jpg",
  alt: "",
  objectFit: "cover",
  objectPosition: "center",
};

const Navigation = ({ handleToggleMenu }) => {
  return (
    <div className={`${styles.block} navigation`}>
      <div className={styles.background}>
        <StaticImage className={styles.image} {...background} />
        <div className={styles.filter}></div>
      </div>

      <div className={styles.container}>
        <div className={styles.header}>
          <img
            className={styles.closeButton}
            src={closeMenuSVG}
            alt="close menu"
            onClick={handleToggleMenu}
          />
        </div>

        <nav className={styles.navigation}>
          <ul>
            {list.map((item, i) => (
              <li>
                <a onClick={handleToggleMenu} href={item.link}>
                  <div className={styles.count}>{("00" + i).slice(-2)}</div>
                  <div className={styles.label}>{item.label}</div>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
