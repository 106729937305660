import React from "react";

import * as styles from "./Header.module.scss";

import logoSVG from "../../assets/images/icons/last/logo_black_letters.svg";
import burgerSVG from "../../assets/images/icons/burger_black.svg";

const logo = {
  src: logoSVG,
  alt: "",
  style: { objectFit: "contain" },
};

const languages = [
  {
    label: "EN",
    link: "/",
    isActive: true,
  },
  {
    label: "GR",
    link: "/el",
  },
];

const Header = ({ handleToggleMenu }) => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        {/* LEFT */}
        <div className={styles.left}>
          <div className={styles.language}>
            {languages.map((lang, i) => (
              <>
                <a
                  href={lang.link}
                  className={lang.isActive ? styles.isActive : ""}
                >
                  {lang.label}
                </a>
                {i < languages.length - 1 && (
                  <span className={styles.seperator}></span>
                )}
              </>
            ))}
          </div>
        </div>

        {/* MIDDLE */}
        <div className={styles.middle}>
          <img
            className={styles.logo}
            {...logo}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        </div>

        {/* RIGHT */}
        <div className={styles.right}>
          <div className={styles.contactLink}>
            <img
              src={burgerSVG}
              alt="burger menu"
              className={styles.burger}
              onClick={handleToggleMenu}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
