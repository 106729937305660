// extracted by mini-css-extract-plugin
export var header = "Header-module--header--2AvKR";
export var container = "Header-module--container--3td42";
export var left = "Header-module--left--1Y0Q-";
export var language = "Header-module--language--lAN_6";
export var isActive = "Header-module--isActive--3xR9X";
export var seperator = "Header-module--seperator--3T-GW";
export var middle = "Header-module--middle--3vk-H";
export var logo = "Header-module--logo--WS63e";
export var right = "Header-module--right--16VJB";
export var contactLink = "Header-module--contactLink--VxX4L";