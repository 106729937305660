import * as React from "react";

import Layout from "../components/Layout";
import HeroBlock from "../blocks/HeroBlock";
import TextBlock from "../blocks/TextBlock";

import RestaurantBlock from "../blocks/RestaurantBlock";
import BarBlock from "../blocks/BarBlock";
import LocationBlock from "../blocks/LocationBlock";
import Map from "../components/Map";
import Footer from "../blocks/Footer";
import Header from "../blocks/Header/Header";
import Navigation from "../blocks/Navigation/Navigation";

const IndexPage = () => {
  const [sectionSpacing, setSectionSpacing] = React.useState(0);

  const [isMenuOpen, setIsMenuOpen] = React.useState(0);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("is--menu-open");
    } else {
      document.body.classList.remove("is--menu-open");
    }
  }, [isMenuOpen]);

  return (
    <Layout>
      <Header handleToggleMenu={handleToggleMenu} />

      <Navigation isMenuOpen={isMenuOpen} handleToggleMenu={handleToggleMenu} />

      <main>
        <HeroBlock handleToggleMenu={handleToggleMenu}>
          <TextBlock />
        </HeroBlock>
        <RestaurantBlock setSectionSpacing={setSectionSpacing} />
        <BarBlock sectionSpacing={sectionSpacing} />
        <LocationBlock />
        <Map />
        <Footer />
      </main>
    </Layout>
  );
};

export default IndexPage;
