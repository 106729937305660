import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../components/BigTextBlock";

import * as styles from "./LocationBlock.module.scss";

import locationSVG from "../../assets/images/icons/location.svg";

const title = "Location | Peros Kolonaki";

const locationImage = {
  src: locationSVG,
  alt: "",
  style: { width: "100%", height: "100%" },
};

const info = {
  Header: (props) => (
    <>
      <div
        className={`${styles.title} fadeInUp`}
        style={{ color: "var(--color-orange)" }}
      >
        The Classy Hill of Athens
      </div>
    </>
  ),
  text: "Two steps from Syntagma square, Kolonaki is a swanky neighbourhood famous for its chic boutiques, fascinating museums, inspired galleries and the most clued-up scene of leisure, gastronomy and entertainment. The area is a beautiful mix of neoclassical, art deco and modernist buildings, making up the Athenians’ most polished quarter. Nestling quietly on the foot of Lycabettus Hill, where some of the most inspiring Greek and international poets, artists, politicians, and celebrities of the past century have rubbed elbows, Kolonaki invites you to explore its timeless beauty!",
  color: "white",
};

const location = {
  src: "../../assets/images/location/peros.jpeg",
  alt: "",
  objectFit: "cover",
};

const LocationBlock = () => {
  return (
    <div className={styles.block} id="contact-n-location">
      {/* Hidden Title */}
      <h2 className={styles.mainTitle}>{title}</h2>

      <div className={styles.container}>
        {/* LEFT */}
        <div className={styles.left}>
          <BigTextBlock className={styles.left} {...info} />
        </div>
        {/* RIGHT */}
        <div className={styles.right}>
          <StaticImage
            className={`${styles.image}  parallaxImage`}
            {...location}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationBlock;
