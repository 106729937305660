import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import BigTextBlock from "../../../components/BigTextBlock";

import * as styles from "./RestaurantMenu.module.scss";

const myMenu = {
  Header: (props) => (
    <>
      <div class="text--header">Restaurant</div>
      <br />
      <div {...props} className={`${styles.title} ${props.className || ""}`}>
        Serving Daily the Finest Tastes
      </div>
    </>
  ),
  text: (
    <>
      Savour delicious bites and dishes with traditional Greek and Mediterranean
      culinary accents and take a pleasant break from the bustling rhythm of
      city life. Having forged its reputation for its selection of elevated
      wines and market-fresh cuisine, Péros is a timeless meeting point serving
      exquisite, premium quality flavours.
      <br />
      <br />
      Creative and traditional tastes prepared with the best raw materials in
      the kitchen of Péros make a perfect match with the cosmopolitan scenery of
      Kolonaki square and invite you to a dining experience that is bound to
      please all your senses.
    </>
  ),
  Footer: (props) => (
    <ul {...props} className={`${styles.links} ${props.className || ""}`}>
            <li>
        <a href="/menu/PEROS-MENU.pdf" target="_blank">
          Food / Cocktails / Drinks Menu
        </a>
      </li>
      <li>
        <a href="/menu/WINE_LIST.pdf" target="_blank">
          Wine List Menu
        </a>
      </li>
    </ul>
  ),
};
const startMenu = {
  Header: (props) => (
    <>
      <div class="text--header">Story</div>
      <br />
      <div {...props} className={`${styles.title} ${props.className || ""}`}>
        The History of an Athenian Classic
      </div>
    </>
  ),
  text: "In 1987, Periklis Korovesis, popularly known as Péros among the locals of Kolonaki, founded his Café-Restaurant in the beautiful Kolonaki square, which had already been established as the ultimate meeting point of the Athenian elite. Here, poets, actors, designers and politicians would meet under the shade of Lycabettus Hill. The reputation of Péros grew over the years and evolved into an all-time favourite urban destination for quality Greek dishes prepared with finesse and authenticity. Since then, it has been welcoming daily new and old-time friends in a cosy and classy atmosphere.",
};
const barMenu = {
  Header: (props) => (
    <>
      <div class="text--header">BAR & CIGAR LOUNGE</div>
      <br />
      <div {...props} className={`${styles.title} ${props.className || ""}`}>
        The Favourite Cafe- Bar of Kolonaki
      </div>
    </>
  ),
  text: "A stylish and elegant establishment, Péros in Kolonaki square can be equally enjoyed for its premium coffee and beverages as for its sophisticated drinks, cocktails and clued-up wine list. It welcomes you from early in the morning to wake up the spirits with the straightforward aroma of coffee and continues to treat you to delicious sips throughout the day. Discover an all-day destination for coffee, after-work evening drinks and inspired city nights!",
};
const extraMenu = {
  Header: (props) => (
    <>
      <div class="text--header" style={{ color: "white" }}>
        All Day Cafe
      </div>
      <br />
      <div
        {...props}
        className={`${styles.title} ${props.className || ""}`}
        style={{ paddingTop: 0 }}
      >
        Delicious Bites From Morning Till Night
      </div>
    </>
  ),
  text: (
    <p style={{ color: "white" }}>
      Discover a destination in Kolonaki square in Athens to indulge in
      delectable flavours from morning till night. Here, flaky, golden
      croissants conspire with fluffy omelettes, scrambled and poached eggs to
      fuel your mornings with favourite breakfast classics, while, later in the
      day, you’ll find a diverse lunch and dinner crowd enjoying the delicious
      cuisine of Péros and soaking up the effervescent vibes of downtown Athens.
      From the heartwarming flavour of a classic Greek Moussaka to the elegant
      taste of an artfully prepared veal carpaccio, the best of traditional and
      modern Mediterranean cuisine awaits!
    </p>
  ),
  className: `${styles.right} ${styles.allDayCafe}`,
};

const image1 = {
  src: "../../../assets/images/peros_2.png",
  alt: "",
  objectFit: "contain",
};
const image2 = {
  src: "../../../assets/images/restaurant/intro/dish.png",
  alt: "",
  objectFit: "contain",
};
const image3 = {
  src: "../../../assets/images/bar/intro/peros_01.jpg",
  alt: "",
  objectFit: "contain",
};
const image4 = {
  src: "../../../assets/images/peros_bg.jpg",
  alt: "",
  objectFit: "cover",
};

const RestaurantMenu = ({ menu = myMenu, type = 1, id }) => (
  <div
    className={`${styles.menu} ${
      [2, 4].indexOf(type) != -1 ? styles.isReverse : ""
    }`}
    data-type={type}
    id={id}
  >
    {/* LEFT */}
    <div className={styles.left}>
      {type !== 4 && (
        <div className={styles.image}>
          {type === 1 && <StaticImage className="parallaxDiv" {...image1} />}
          {type === 2 && <StaticImage className="parallaxDiv" {...image2} />}
          {type === 3 && <StaticImage className="parallaxDiv" {...image3} />}
        </div>
      )}
    </div>

    {type === 4 && (
      <div className={styles.background}>
        <StaticImage {...image4} />
      </div>
    )}

    {/* RIGHT */}
    <BigTextBlock
      className={styles.right}
      {...(type === 1
        ? menu
        : type === 2
        ? startMenu
        : type === 3
        ? barMenu
        : type === 4
        ? extraMenu
        : {})}
    />
  </div>
);

export default RestaurantMenu;
