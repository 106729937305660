import React from "react";

import * as styles from "./BarBlock.module.scss";

import BarIntro from "./BarIntro/";
import BarAmbience from "./BarAmbience/";
import BarContact from "./BarContact/BarContact";

import bg from "../../assets/images/peros_second_bg.jpg";
import RestaurantMenu from "../RestaurantBlock/RestaurantMenu/RestaurantMenu";

const title = "Bar | Peros Kolonaki";

const background = {
  src: bg,
  alt: "",
};

const BarBlock = ({ sectionSpacing }) => {
  return (
    <div className={styles.block} style={{ paddingTop: `${sectionSpacing}px` }}>
      {/* Hidden Title */}
      <h2 className={styles.title}>{title}</h2>

      {/* BACKGOUND */}
      <div className={styles.background}>
        <img {...background} className="parallaxDiv" />
      </div>

      <div className={styles.container}>
        {/* INTRO */}
        {/* <BarIntro /> */}
        <RestaurantMenu type={3} id="bar-n-cigar-lounge" />

        {/* AMBIENCE */}
        <BarAmbience id="special-events" />

        {/* CONTACT */}
        {/* <BarContact /> */}
      </div>
    </div>
  );
};

export default BarBlock;
