import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./RestaurantBlock.module.scss";

import SwiperBlock from "../../components/SwiperBlock/SwiperBlock";

import RestaurantIntro from "./RestaurantIntro/RestaurantIntro";
import RestaurantMenu from "./RestaurantMenu/RestaurantMenu";
import RestaurantContact from "./RestaurantContact/RestaurantContact";

const title = "Restaurant | Peros Kolonaki";

/* SWIPER */
const swiperImages = [
  <StaticImage
    src={"../../assets/images/restaurant/swiper/peros_01.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  <StaticImage
    src={"../../assets/images/restaurant/swiper/peros_02.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  // <StaticImage
  //   src={"../../assets/images/restaurant/swiper/peros_03.jpg"}
  //   className={styles.swiperImage}
  //   alt={""}
  //   objectFit={"cover"}
  // />,
  <StaticImage
    src={"../../assets/images/restaurant/swiper/peros_04.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
  <StaticImage
    src={"../../assets/images/restaurant/swiper/peros_05.jpg"}
    className={styles.swiperImage}
    alt={""}
    objectFit={"cover"}
  />,
];

const RestaurantBlock = ({ setSectionSpacing }) => {
  const [slidesPerView, setSlidesPerView] = useState(2);

  const handleResize = () => {
    if (window.innerWidth > 800) {
      setSlidesPerView(2);
    } else if (window.innerWidth <= 800) {
      setSlidesPerView(1);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.block}>
      {/* Hidden Title */}
      <h2 className={styles.title}>{title}</h2>

      <div className={styles.container}>
        {/* INTRO */}
        <RestaurantMenu type={2} id="the-story" />

        {/* SWIPER */}
        <div className={`${styles.swiper} restaurant-swiper`}>
          <SwiperBlock
            slides={swiperImages}
            slidesPerView={slidesPerView}
            className={"is--orange"}
          />
        </div>

        {/* OUR MENU */}
        <RestaurantMenu type={1} id="restaurant" />

        <RestaurantMenu type={4} id="all-day-cafe" />

        {/* CONTACT */}
        {/* <RestaurantContact setSectionSpacing={setSectionSpacing} /> */}
      </div>
    </div>
  );
};

export default RestaurantBlock;
