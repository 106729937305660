import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import * as styles from "./TextBlock.module.scss";

const background = {
  src: "../../assets/images/textblock/bg.jpg",
  alt: "",
  quality: 100,
};

const title = "An Urban Meeting Point Since 1987";

const text =
  "Experience the revamped Péros, the iconic all-day destination in Athens that has become a landmark of Kolonaki square since 1987 when it was first introduced in Tsakalof street. More than 30 years later, Péros remains the top upscale hangout for Athenian socializing, adding sparkle and flavour to city life and continuing an enduring legacy.";

const TextBlock = () => {
  return (
    <div className={styles.block}>
      {/* BACKGROUND */}
      {/* <StaticImage
        className={`${styles.background} parallaxImage`}
        {...background}
      /> */}

      <div className={styles.container}>
        <div className={`${styles.text} fadeInUp`}>
          <div className={styles.title}>{title}</div>
          <br />
          <div>{text}</div>
        </div>
      </div>
    </div>
  );
};

export default TextBlock;
