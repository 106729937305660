import React from "react";

import Link from "../../components/Link";

import * as styles from "./Footer.module.scss";

import restaurantSVG from "../../assets/images/icons/rest_logo_white.svg";
import barSVG from "../../assets/images/icons/bar_logo_white.svg";
import logoSVG from "../../assets/images/icons/logo.svg";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

import instagramSVG from "../../assets/images/icons/instagram_orange.svg";
import facebookSVG from "../../assets/images/icons/facebook_orange.svg";

const restaurantImage = {
  src: restaurantSVG,
  alt: "",
};
const barImage = {
  src: barSVG,
  alt: "",
};
const logoImage = {
  src: logoSVG,
  alt: "",
};

const instagram = {
  link: {
    href: "https://www.instagram.com/peros_kolonaki/",
  },
  img: {
    src: instagramSVG,
    alt: "",
  },
};
const facebook = {
  link: {
    href: "https://www.facebook.com/Peros-Kolonaki-103796948633175",
  },
  img: {
    src: facebookSVG,
    alt: "",
  },
};

const Footer = () => (
  <footer className={styles.block} id="contact">
    <div className={styles.container}>
      {/* CONTENT */}
      <div className={styles.content}>
        <First />
        <Third />

        <div className={styles.bottom}>
          <div className={styles.left}>2021 © Peros Kolonaki</div>
          <div className={styles.right}>
            Design & Development by{" "}
            <Link href="https://www.f-design.gr/">F-Design</Link>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className={styles.footer}></div>
    </div>
  </footer>
);

export default Footer;

const First = () => (
  <div className={styles.first}>
    {/* LOGO */}
    <img className={styles.logo} {...logoImage} />
  </div>
);

/* FOURTH */
const Third = () => (
  <div className={styles.third}>
    <div className={styles.social}>
      <SocialMedia {...instagram} />
      <SocialMedia {...facebook} />
    </div>
  </div>
);
