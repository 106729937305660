import React from "react";

import * as styles from "./TextField.module.scss";

const TextField = ({ text, className, color = "blue" }) => (
  <div className={`${styles.text} ${className || ""}`} data-color={color}>
    {text}
  </div>
);

export default TextField;
