import React from "react";

import TextField from "../TextField";

import * as styles from "./BigTextBlock.module.scss";

const BigTextBlock = ({ className, Header, text, Footer, color = "blue" }) => {
  return (
    <div className={`${styles.block} ${className || ""}`} data-color={color}>
      <div className={styles.container}>
        {/* Logo */}
        {Header && <Header className={`${styles.header} fadeInUp`} />}

        {/* Text */}
        <TextField
          color={color}
          className={`${styles.text} fadeInUp`}
          text={text}
        />

        {/* CTA Button */}
        {Footer && <Footer className={`${styles.footer} fadeInUp`} />}
      </div>
    </div>
  );
};

export default BigTextBlock;
